import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Container from "./container"
import Heading from "./heading"
import TextWithImage from "./textWithImage"

const ProcessingList = ({ section }) => (
  <Wrapper>
    <Container>
      <Heading as="h3">{section.title}</Heading>
      <div dangerouslySetInnerHTML={{ __html: section.text }} />
      {section.processing.map((process, index) => (
        <TextWithImage
        key={process.id}
          section={process}
          headingShadow={false}
          headingDowncase={true}
          even={index % 2 === 0}
        />
      ))}
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  padding: 3rem ${DesignTokens.spacing[5]};
  overflow: hidden;
  h3 {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
`

export default ProcessingList
