import React from "react"
import styled from "styled-components"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import DesignTokens from "./designTokens"
import Container from "./container"

const CustomTabs = ({ section }) => (
  <Wrapper>
    <CustomContainer>
      <img src={section.image.url} alt={section.image.alt} />
      <Tabs>
        <StyledTabList>
          {section.processes.map(process => (
            <StyledTab key={process.id}>{process.title}</StyledTab>
          ))}
        </StyledTabList>
        {section.processes.map(process => (
          <TabPanel key={process.id}>
            <div dangerouslySetInnerHTML={{ __html: process.text }} />
          </TabPanel>
        ))}
      </Tabs>
    </CustomContainer>
  </Wrapper>
)

const Wrapper = styled.div`
  padding: 0 ${DesignTokens.spacing[5]};
`

const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  margin: 0 0 ${DesignTokens.spacing[7]};
`

const StyledTab = styled(Tab)`
    font-size: ${DesignTokens.fontSizes[5]};
  &.react-tabs__tab--selected {
    font-weight: 900;
    color: ${DesignTokens.colors.primary[500]};
  }
  &:hover {
    cursor: pointer;
    color: ${DesignTokens.colors.primary[500]};
  }

`

const CustomContainer = styled(Container)`
  img {
    margin-bottom: ${DesignTokens.spacing[6]};
    @media screen and (min-width: 769px) {
      margin-right: ${DesignTokens.spacing[7]};
    }
  }
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`

export default CustomTabs
