import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/callToAction"
import Intro from "../components/intro"
import Header from "../components/header"
import CustomTabs from "../components/customTabs"
import ProcessingList from "../components/processingList"
import Footer from "../components/footer"

const ProcessPage = ({ data: { page } }) => {
  return (
    <Layout>
      <SEO title={page.title} />
      <Header
        title={page.title}
        image={page.heroImage ? page.heroImage.url : null}
      />
      <Intro title={page.subtitle} heading="h2" />
      {page.content.map(section => (
        <section key={section.id}>
          {section.__typename === "DatoCmsTab" && (
            <CustomTabs section={section} />
          )}
          {section.__typename === "DatoCmsProcessingList" && 
            <ProcessingList section={section} />
          }
        </section>
      ))}
      <CallToAction cta={page.callToAction} />
      <Footer />
    </Layout>
  )
}

export default ProcessPage

export const query = graphql`
  query ProcessPageQuery {
    page: datoCmsProcessPage {
      title
      subtitle
      heroImage {
        url
      }
      callToAction {
        image {
          url
        }
        text
        url
        callToAction
      }
      content {
        ... on DatoCmsTab {
          id
          image {
            url
            alt
          }
          processes {
            text
            title
            id
          }
        }
        ... on DatoCmsProcessingList {
          id
          title
          text
          processing {
            id
            title
            text
            image {
              alt
              url
            }
          }
        }
      }
    }
  }
`
